<template>
  <div>
    <div v-if="!isLoading" class="box box-default">
      <div class="box-header">
        <div class="flex align-items-center justify-between">
          <h3 class="box-title">Merchant Autonomy</h3>
          <div class="flex align-items-center">
            <span class="text-bold text-danger">{{ searching }}</span>
            <input ref="searchInput" v-model="keyword" aria-label="search" class="form-control ml-8 mr-8"
              placeholder="Search..." type="text" @keyup="search($event)" />
          </div>
        </div>
      </div>
      <div class="box-body no-padding">
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-condensed">
            <thead>
              <tr>
                <th class="pointer" @click="sort('ID')">
                  ID
                  <span v-if="sortKey === 'ID'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('OwnerNickName')">
                  Merchant
                  <span v-if="sortKey === 'OwnerNickName'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('CoinName')">
                  AltCoin
                  <span v-if="sortKey === 'CoinName'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('AutonomyAccount')">
                  Autonomy Account
                  <span v-if="sortKey === 'AutonomyAccount'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('AutonomyUser')">
                  Autonomy User
                  <span v-if="sortKey === 'AutonomyUser'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('AutonomyPass')">
                  Autonomy Password
                  <span v-if="sortKey === 'AutonomyPass'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th class="pointer" @click="sort('AutonomyServer')">
                  Autonomy Server
                  <span v-if="sortKey === 'AutonomyServer'" class="ml-4">
                    <i class="fa fa-sort"></i>
                  </span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="records.length > 0">
                <tr v-for="(record, index) in records" :key="index">
                  <td>{{ record.ID }}</td>
                  <td class="no-wrap">
                    <router-link :to="{ name: 'user-detail', params: { id: record.MerchantID } }" tag="a"
                      v-if="record.OwnerNickName">
                      {{ record.ShopTitle + ' (' + record.OwnerNickName + ')' }}
                    </router-link>
                  </td>
                  <td class="no-wrap">
                    <span v-if="record.CoinName">{{ record.CoinName + ' (' + record.LongName + ')' }}</span>
                  </td>
                  <td class="no-wrap">{{ record.AutonomyAccount }}</td>
                  <td class="no-wrap">{{ record.AutonomyUser }}</td>
                  <td class="no-wrap">{{ record.AutonomyPass }}</td>
                  <td class="no-wrap">{{ record.AutonomyServer }}</td>
                  <td class="action-sm pr-16"></td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="8">No record found!</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div v-if="meta.total" class="footer">
          <pagination :meta="meta" @pageChange="updatePage"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { mapGetters } from "vuex";
import { START_LOADING, STOP_LOADING } from "@/store/keys";
import { formatDate, saveNotification } from "@/lib/utils";

export default {
  name: "MerchantAutonomy",
  components: {
    Pagination,
  },
  data() {
    return {
      records: [],
      meta: {
        total: 0,
        page: 1,
        offset: 0,
        limit: Number.parseInt(process.env.VUE_APP_LIMIT, 10),
      },
      keyword: "",
      searching: "",
      sortKey: "ID",
      order: "desc",
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "jwtToken",
      "currentUser",
    ]),
  },
  methods: {
    updatePage(page) {
      this.getPaginatedRecords(page);
    },
    search(e) {
      if (e.key !== "/" && e.key !== "Control") {
        this.searching = "Searching...";
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          this.getPaginatedRecords(1);
        }, 500);
      }
    },
    sort(key) {
      if (key !== this.sortKey) {
        this.order = "desc";
      } else {
        this.order = this.order === "asc" ? "desc" : "asc";
      }
      this.sortKey = key;
      this.getPaginatedRecords(1, false);
    },
    getPaginatedRecords(page = 1, isLoading = true) {
      if (isLoading) {
        this.$store.commit(START_LOADING);
      }
      const apiBaseUrl = process.env.VUE_APP_ADMIN_API_URL;
      this.$http.get(`${apiBaseUrl}/?Call=ListMisc&type=merchant_autonomy`, {
        headers: {
          Authorization: "Bearer " + this.jwtToken,
        },
        params: {
          page,
          keyword: this.keyword,
          limit: Number.parseInt(this.meta.limit),
          order: this.sortKey,
          orderBy: this.order,
        },
      }).then((response) => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (response.status === 200) {
          this.records = response.data.data.records;
          this.meta = response.data.data.meta;
          if (this.keyword) {
            this.$nextTick(() => this.$refs.searchInput.focus());
          }
        }
      }).catch(error => {
        this.$store.commit(STOP_LOADING);
        this.searching = "";
        if (error.data) {
          this.$toast.fire("", error.data.message, "error");
          saveNotification(error.data.message);
        }
      });
    },
    formatDate(val) {
      return formatDate(val);
    },
    formatBoolean(val) {
      const label = val ? "Yes" : "No";
      const lblClass = val ? "label-success" : "label-danger";
      return `<label class="label ${lblClass}">${label}</label>`;
    },
    handleSlashKey(e) {
      if (e.key === "/") {
        e.preventDefault();
        this.$refs.searchInput.focus();
      }
    },
  },
  mounted() {
    this.getPaginatedRecords();
    window.addEventListener("keydown", this.handleSlashKey);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleSlashKey);
    this.$store.commit(STOP_LOADING);
  },
};
</script>

<style scoped></style>
